export default [
  /* 0 */ {
    mapID: '47ea1a5e-d2bd-433b-ad68-cb11cb06b21c',
    parameters: {depthTest: false},
  },
  /* 1 */ {
    mapID: '47ea1a5e-d2bd-433b-ad68-cb11cb06b21c',
    parameters: {depthTest: false},
    orbit: true
  },
  /* 2 */ {
    mapID: '14da7fef-f5b2-4662-9af0-bfb53bf4fafd',
    props: {weightsTextureSize: 256}
  },
  /* 3 */ {
    mapID: '797b8a45-cab1-4608-bd1c-9f033bea5d15',
    orbit: true,
    props: {coverage: 0.7}
  },
  /* 4 */ {
    mapID: '598c52f5-30f7-40ce-a8b8-c1d5a8576eb5',
  },
  /* 5 */ {
    mapID: 'a3b38d4e-d8f6-48b6-abf7-df7f5c0dcdf5',
  },
  /* 6 */ {
    mapID: 'ae2df928-c4e3-4737-bbe5-4822083b8079',
    props: {coverage: 0.7}
  }
];
