import { TripsLayer } from '@deck.gl/geo-layers';
import Blending from './blending';

function compareTrips(a, b) {
  if (a.properties.delivery_id < b.properties.delivery_id) {
    return -1;
  }
  else if (a.properties.delivery_id > b.properties.delivery_id) {
    return 1;
  }
  else {
    // Same delivery, we look at the timestamp
    if (a.properties.date_time < b.properties.date_time) {
      return -1;
    }
    else {
      return 0;
    }
  }
}

function getTripsData(data) {
  let trips = [];
  let featurePath = [], featureTimestamps = [];
  let deliveryID = data[0].properties.delivery_id;

  const TIME0 = new Date('2022-04-02T15:00:00.000Z');

  // Order the features by delivery ID and timestamp
  data.sort(compareTrips);

  let min = 10000000000;
  let max = 0;
  for (let feature of data) {
    if (feature.properties.delivery_id != deliveryID) {
      // Add the path and the timestamps
      trips.push({
        path: featurePath,
        timestamps: featureTimestamps
      });
      featurePath = [];
      featureTimestamps = [];
      deliveryID = feature.properties.delivery_id;
    }
    else {
      featurePath.push(feature.geometry.coordinates);
      const ts = (new Date(feature.properties.date_time) - TIME0) / 1000;
      min = Math.min(min, ts);
      max = Math.max(max, ts);
      featureTimestamps.push(ts);
    }
  }

  // console.log(min, max);
  return trips;
}

export function getTripsLayer(cartoLayer) {

  const tripsData = getTripsData(cartoLayer.props.data.features);

  const tripsLayer = new TripsLayer({
    id: 'trips-layer',
    data: tripsData,
    getPath: d => d.path,
    // deduct start timestamp from each data point to avoid overflow
    getTimestamps: d => d.timestamps,
    getColor: cartoLayer.props.getFillColor,
    opacity: cartoLayer.props.opacity,
    widthMinPixels: 2 * cartoLayer.props.getPointRadius,
    capRounded: true,
    fadeTrail: true,
    trailLength: 5000,
    currentTime: 0,
    parameters: {
      depthTest: false,
      ...Blending.ADDITIVE
    }
  });

  return tripsLayer;
};
